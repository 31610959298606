import groq from 'groq'

import { blocksFragment } from './blocks'
import { blogPostWithoutBodyFragment } from './blog'
import { complexContentFragment } from './content'
import { imageFragment } from './image'
import { productFragment } from './product'
import { muxVideoFragment, videoFragment } from './video'

const gridModuleFragment = groq`
  size,
  background,
  noColumnGaps,
  noRowGaps,
  reverseSequence,
  spacing,
  columns[] {
    _key,
    sizes[] {
      breakpoint,
      width,
      justify,
      align,
      start,
    },
    blocks[] {
      ${blocksFragment}
    },
    spacing,
  },
`

const heroModuleFragment = groq`
  bgType,
  content[] {
    ${complexContentFragment}
  },
  contentPosition,
  photos {
    mobilePhoto {
      ${imageFragment}
    },
    desktopPhoto {
      ${imageFragment}
    },
  },
  ${muxVideoFragment}
  "vimeoVideo": *[
    _type == "vimeo.videoAsset" &&
    ^.video.id == _id
  ][0],
`

const dividerPhotoModuleFragment = groq`
  photo {
    ${imageFragment}
  },
`

const reviewsStringsFragment = groq`
  reviewsSingular,
  reviewsPlural,
  reviewsRatingBasedOnSingular,
  reviewsRatingBasedOnPlural,
  reviewsNoReviews,
  reviewsCarouselTitle,
  reviewsVerifiedBuyer,
  reviewsWriteReview,
  reviewsFormAuthorInputLabel,
  reviewsFormAuthorInputPlaceholder,
  reviewsFormAuthorMissing,
  reviewsFormEmailInputLabel,
  reviewsFormEmailInputPlaceholder,
  reviewsFormEmailMissing,
  reviewsFormReviewRatingInputLabel,
  reviewsFormReviewRatingMissing,
  reviewsFormReviewTitleInputLabel,
  reviewsFormReviewTitleInputPlaceholder,
  reviewsFormReviewTitleMissing,
  reviewsFormReviewMessageInputLabel,
  reviewsFormReviewMessageInputPlaceholder,
  reviewsFormReviewMessageMissing,
  reviewsFormSubmit,
  reviewsFormErrorMessage[] {
    ${complexContentFragment}
  },
  reviewsFormSuccessMessage[] {
    ${complexContentFragment}
  },
`

const reviewWidgetModuleFragment = groq`
  type,
  "reviewsStrings": *[
    _type == "strings" &&
    locale == $locale
  ][0] {
    ${reviewsStringsFragment}
  },
`

const cartFormModuleFragment = groq`
  active,
`

const simpleMarqueeItemFragment = groq`
  text,
`

const photoMarqueeItemFragment = groq`
  "photo": {
    ${imageFragment}
  },
`

const productMarqueeItemFragment = groq`
  _id,
  "product": *[
    _type == "product" &&
    _id == ^._ref
  ][0] {
    ${productFragment}
  },
`

const marqueeModuleFragment = groq`
  content[] {
    ${complexContentFragment}
  },
  contentAlignment,
  speed,
  maxItemWidth,
  reverse,
  pausable,
  items[] {
    _key,
    _type,
    _type == "simple" => {
      ${simpleMarqueeItemFragment}
    },
    _type == "photo" => {
      ${photoMarqueeItemFragment}
    },
    _type == "product" => {
      ${productMarqueeItemFragment}
    },
  },
`

const collectionStringsFragment = groq`
  collectionProducts,
  collectionProductCount,
  collectionSortLabel,
  collectionSortDescription,
  collectionShowFiltersLabel,
  collectionFilters,
  collectionRemoveFilter,
  collectionClearAllLabel,
  collectionClearFiltersLabel,
  collectionFilterResultsLabel,
  collectionFilterNoResultsLabel,
  collectionFilterModalClose,
  collectionFilterNoResults[] {
    ${complexContentFragment}
  },
`

const collectionGridFragment = groq`
  active,
  "title": ^.title,
  "paginationLimit": *[
    _type == "cartSettings" &&
    locale == $locale
  ][0].paginationLimit,
  "filter": *[
    _type == "cartSettings" &&
    locale == $locale
  ][0].filter {
    isActive,
    groups[] {
      _key,
      title,
      "slug": slug.current,
      display,
      options[]->{
        type,
        title,
        "slug": slug.current,
        "color": color->color,
      },
    },
  },
  "sort": *[
    _type == "cartSettings" &&
    locale == $locale
  ][0].sort {
    isActive,
    options[] {
      type,
      title,
    },
  },
  "collectionStrings": *[
    _type == "strings" &&
    locale == $locale
  ][0] {
    ${collectionStringsFragment}
  },
`

const blogPostGridModuleFragment = groq`
  "posts": *[
    _type == "blogPost" &&
    type != "template" &&
    (!defined(^.author) || author->slug.current == ^.author->slug.current) &&
    (!defined(^.category) || categories[]->slug.current match ^.category->slug.current)
  ]
    | order(_createdAt desc)
    | order(date desc)
  {
    ${blogPostWithoutBodyFragment}
  },
  options,
`

const blogPostHeaderModuleFragment = groq`
  $blogPostSlug != null => {
    "post": *[
      _type == "blogPost" &&
      type != "template" &&
      slug.current == $blogPostSlug
    ]
      | order(_updatedAt desc)
    [0] {
      ${blogPostWithoutBodyFragment}
    },
  },
  options,
`

const blogPostBodyModuleFragment = groq`
  content[] {
    ${complexContentFragment}
  },
`

const blogPostReadMoreModuleFragment = groq`
  $blogPostSlug != null => {
    "posts": *[
      _type == "blogPost" &&
      type != "template" &&
      slug.current != $blogPostSlug
    ] {
      "matchingCategoryCount": count((categories[]._ref)[
        @ in *[
          _type == "blogPost" &&
          type != "template" &&
          slug.current == $blogPostSlug
        ]
          | order(_updatedAt desc)
        [0].categories[]._ref
      ]),
      ${blogPostWithoutBodyFragment}
    }
      | order(_createdAt desc)
      | order(date desc)
      | order(matchingCategoryCount desc)
    [0...5],
  },
`

const blogCategoryPostGridGridModuleFragment = groq`
  $blogCategorySlug != null => {
    "posts": *[
      _type == "blogPost" &&
      type != "template" &&
      categories[]->slug.current match $blogCategorySlug
    ]
      | order(_createdAt desc)
      | order(date desc)
    {
      ${blogPostWithoutBodyFragment}
    },
  },
  options,
`

const blogAuthorPostGridModuleFragment = groq`
  $blogAuthorSlug != null => {
    "posts": *[
      _type == "blogPost" &&
      type != "template" &&
      author->slug.current == $blogAuthorSlug
    ]
      | order(_createdAt desc)
      | order(date desc)
    {
      ${blogPostWithoutBodyFragment}
    },
  },
  options,
`

const pricingPlansModuleFragment = groq`
  plans[] {
    name,
    price,
    description,
    featured,
    features,
    button {
      label,
      url,
      "page": page->{
        "id": _id,
        "type": _type,
        "slug": slug.current,
      },
    },
  },
`

const teamMembersGridModuleFragment = groq`
  title,
  subtitle,
  "teamMembers": teamMembers[]->{
    name,
    "slug": slug.current,
    jobTitle,
    about,
    linkedInUrl,
    blogAuthor->{
      "slug": slug.current,
    },
    photo {
      ${imageFragment}
    },
  },
`

const techStackModuleFragment = groq`
  ...,
  rows[] {
    ...,
    items[] {
      ...,
      _type == 'figure' => {
        ${imageFragment}
      },
    }
  },
`

const baseModulesFragment = groq`
  _key,
  _type,
  _type == "grid" => {
    ${gridModuleFragment}
  },
  _type == "hero" => {
    ${heroModuleFragment}
  },
  _type == "dividerPhoto" => {
    ${dividerPhotoModuleFragment}
  },
  _type == "reviewWidget" => {
    ${reviewWidgetModuleFragment}
  },
  _type == "cartForm" => {
    ${cartFormModuleFragment}
  },
  _type == "video" => {
    ${videoFragment}
  },
  _type == "marquee" => {
    ${marqueeModuleFragment}
  },
  _type == "collectionGrid" => {
    ${collectionGridFragment}
  },
  _type == "blogPostGrid" => {
    ${blogPostGridModuleFragment}
  },
  _type == "blogPostHeader" => {
    ${blogPostHeaderModuleFragment}
  },
  _type == "blogPostBody" => {
    ${blogPostBodyModuleFragment}
  },
  _type == "blogPostReadMore" => {
    ${blogPostReadMoreModuleFragment}
  },
  _type == "blogCategoryPostGrid" => {
    ${blogCategoryPostGridGridModuleFragment}
  },
  _type == "blogAuthorPostGrid" => {
    ${blogAuthorPostGridModuleFragment}
  },
  _type == "pricingPlans" => {
    ${pricingPlansModuleFragment}
  },
  _type == "teamMembersGrid" => {
    ${teamMembersGridModuleFragment}
  },
  _type == "techStack" => {
    ${techStackModuleFragment}
  },
`

const predefinedPageModuleModuleFragment = groq`
  "pageModules": pageModule->pageModules[] {
    ${baseModulesFragment}
  },
`

export const modulesFragment = groq`
  ${baseModulesFragment}
  _type == "predefinedPageModule" => {
    ${predefinedPageModuleModuleFragment}
  },
`
