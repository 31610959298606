import groq from 'groq'

import { imageFragment } from './image'
import { linkPageFragment } from './link'
import { videoFragment } from './video'
import { buttonStyleFragment } from './link'

const markDefFragment = groq`
  ...,
  _type == "link" => {
    isButton == true => {
      "buttonStyles": buttonStyle {
        ${buttonStyleFragment}
      },
    },
    page->{
      ${linkPageFragment}
    },
  },
`

export const simpleContentFragment = groq`
  ...,
  markDefs[] {
    ${markDefFragment}
  },
`

const addToCartButtonFragment = groq`
  style {
    ${buttonStyleFragment}
  },
  productVariant->{
    price,
    inStock,
    "id": variantID,
  },
`

const videoButtonFragment = groq`
  thumbnail {
    ${imageFragment}
  },
  video {
    ${videoFragment}
  },
  style {
    ${buttonStyleFragment}
  },
`

export const complexContentFragment = groq`
  ...,
  markDefs[] {
    ${markDefFragment}
  },
  _type == "addToCartButton" => {
    ${addToCartButtonFragment}
  },
  _type == "figure" => {
    ${imageFragment}
  },
  _type == "videoButton" => {
    ${videoButtonFragment}
  },
`
