import dynamic from 'next/dynamic'

import {
  SanityBlogAuthorPostGridModule,
  SanityBlogCategoryPostGridModule,
  SanityBlogPostGridModule,
  SanityBlogPostHeaderModule,
  SanityBlogPostBodyModule,
  SanityCartFormModule,
  SanityCollectionGridModule,
  SanityDividerPhotoModule,
  SanityGridModule,
  SanityHeroModule,
  SanityMarqueeModule,
  SanityModule,
  SanityModuleType,
  SanityReviewWidgetModule,
  SanityVideoModule,
  SanityPricingPlansModule,
  SanityBlogPostReadMoreModule,
  SanityTeamMembersGridModule,
  SanityTechStackModule,
  SanityPredefinedPageModuleModule,
} from '@data/sanity/queries/types/modules'
import {
  SanityProductFragment,
  SanityProductVariantFragment,
} from '@data/sanity/queries/types/product'
import { SanityCart } from '@data/sanity/queries/types/site'
import { SanityBlogPostWithoutBody } from '@data/sanity/queries/types/blog'
import { Reviews } from '@lib/review'

const BlogPostGrid = dynamic(() => import('./blog/blog-post-grid'))
const BlogPostHeader = dynamic(() => import('./blog/blog-post-header'))
const BlogPostBody = dynamic(() => import('./blog/blog-post-body'))
const BlogPostReadMore = dynamic(() => import('./blog/blog-post-read-more'))
const DividerPhoto = dynamic(() => import('./divider-photo'))
const Grid = dynamic(() => import('./grid'))
const Hero = dynamic(() => import('./hero'))
const Marquee = dynamic(() => import('./marquee'))
const ReviewWidget = dynamic(() => import('./review-widget'))
const CartForm = dynamic(() => import('./shop/cart/form'))
const Collection = dynamic(() => import('./shop/collection'))
const ProductHero = dynamic(() => import('./shop/product-hero'))
const VideoModule = dynamic(() => import('./video'))
const PricingPlans = dynamic(() => import('./pricing-plans'))
const TeamMembersGrid = dynamic(() => import('./team-members-grid'))
const TechStack = dynamic(() => import('./tech-stack'))

interface ModuleProps {
  module: SanityModule
  product?: SanityProductFragment
  activeVariant?: SanityProductVariantFragment
  onVariantChange?: (variantId: number) => void
  collectionProducts?: SanityProductFragment[]
  featuredProductIds?: number[]
  cartSettings?: SanityCart
  reviews?: Reviews
}

const Module = ({
  module,
  product,
  activeVariant,
  onVariantChange,
  collectionProducts,
  featuredProductIds,
  cartSettings,
  reviews,
}: ModuleProps) => {
  switch (module._type as SanityModuleType) {
    case SanityModuleType.GRID: {
      const {
        columns,
        size,
        spacing,
        background,
        noColumnGaps,
        noRowGaps,
        reverseSequence,
      } = module as SanityGridModule

      return (
        <Grid
          columns={columns}
          size={size}
          spacing={spacing}
          background={background}
          noColumnGaps={noColumnGaps}
          noRowGaps={noRowGaps}
          reverseSequence={reverseSequence}
        />
      )
    }

    case SanityModuleType.HERO: {
      const { bgType, contentPosition, content, photos, muxVideo, vimeoVideo } =
        module as SanityHeroModule

      return (
        <Hero
          bgType={bgType}
          contentPosition={contentPosition}
          content={content}
          photos={photos}
          muxVideo={muxVideo}
          vimeoVideo={vimeoVideo}
          overlay
        />
      )
    }

    case SanityModuleType.DIVIDER_PHOTO: {
      const { photo } = module as SanityDividerPhotoModule

      return <DividerPhoto photo={photo} />
    }

    case SanityModuleType.REVIEW_WIDGET: {
      const { reviewsStrings, type } = module as SanityReviewWidgetModule

      if (!reviews) {
        return null
      }

      return (
        <ReviewWidget
          reviewsStrings={reviewsStrings}
          type={type}
          product={product}
          activeVariant={activeVariant}
          reviews={reviews}
        />
      )
    }

    case SanityModuleType.PRODUCT_HERO: {
      if (!product || !activeVariant || !onVariantChange) {
        return null
      }

      return (
        <ProductHero
          product={product}
          activeVariant={activeVariant}
          onVariantChange={onVariantChange}
        />
      )
    }

    case SanityModuleType.COLLECTION_GRID: {
      const {
        active,
        collectionStrings,
        paginationLimit,
        title,
        sort,
        filter,
      } = module as SanityCollectionGridModule
      const products = collectionProducts ?? []

      if (!active || products.length === 0) {
        return null
      }

      return (
        <Collection
          collectionStrings={collectionStrings}
          products={products}
          featuredProductIds={featuredProductIds ?? []}
          paginationLimit={paginationLimit}
          title={title}
          sort={sort}
          filter={filter}
        />
      )
    }

    case SanityModuleType.CART_FORM: {
      const { active } = module as SanityCartFormModule

      if (!active || !cartSettings) {
        return null
      }

      return <CartForm cartSettings={cartSettings} />
    }

    case SanityModuleType.VIDEO: {
      const {
        type,
        vimeoVideo,
        muxVideo,
        settings,
        aspectRatio,
        borderRadius,
      } = module as SanityVideoModule

      return (
        <VideoModule
          type={type}
          vimeoVideo={vimeoVideo}
          muxVideo={muxVideo}
          settings={settings}
          aspectRatio={aspectRatio}
          borderRadius={borderRadius}
        />
      )
    }

    case SanityModuleType.MARQUEE: {
      const {
        content,
        contentAlignment,
        items,
        speed,
        maxItemWidth,
        reverse,
        pausable,
      } = module as SanityMarqueeModule

      return (
        <Marquee
          content={content}
          contentAlignment={contentAlignment}
          items={items}
          speed={speed}
          maxItemWidth={maxItemWidth}
          reverse={reverse}
          pausable={pausable}
        />
      )
    }

    case SanityModuleType.BLOG_POST_GRID: {
      const { posts, options } = module as SanityBlogPostGridModule

      return <BlogPostGrid posts={posts} options={options} />
    }

    case SanityModuleType.BLOG_POST_HEADER: {
      const { post, options } = module as SanityBlogPostHeaderModule

      if (!post) {
        return null
      }

      return <BlogPostHeader post={post} options={options} />
    }

    case SanityModuleType.BLOG_POST_BODY: {
      const { content } = module as SanityBlogPostBodyModule

      return <BlogPostBody content={content} />
    }

    case SanityModuleType.BLOG_POST_READ_MORE: {
      const { posts } = module as SanityBlogPostReadMoreModule

      return <BlogPostReadMore posts={posts} />
    }

    case SanityModuleType.BLOG_CATEGORY_POST_GRID: {
      const { posts, options } = module as SanityBlogCategoryPostGridModule

      return <BlogPostGrid posts={posts} options={options} />
    }

    case SanityModuleType.BLOG_AUTHOR_POST_GRID: {
      const { posts, options } = module as SanityBlogAuthorPostGridModule

      return <BlogPostGrid posts={posts} options={options} />
    }

    case SanityModuleType.PRICING_PLANS: {
      const { plans } = module as SanityPricingPlansModule

      return <PricingPlans plans={plans} />
    }

    case SanityModuleType.TEAM_MEMBERS_GRID: {
      const { title, subtitle, teamMembers } =
        module as SanityTeamMembersGridModule

      return (
        <TeamMembersGrid
          title={title}
          subtitle={subtitle}
          teamMembers={teamMembers}
        />
      )
    }

    case SanityModuleType.PREDEFINED_PAGE_MODULE: {
      const { pageModules } = module as SanityPredefinedPageModuleModule

      return (
        <>
          {pageModules?.map((pageModule) => (
            <Module key={pageModule._key} module={pageModule} />
          ))}
        </>
      )
    }

    case SanityModuleType.TECH_STACK: {
      const { heading, content, rows } = module as SanityTechStackModule

      return <TechStack heading={heading} content={content} rows={rows} />
    }
  }
}

export default Module
