import groq from 'groq'

export const vimeoVideoFragment = groq`
  "vimeoVideo": *[
    _type == "vimeo.videoAsset" &&
    ^.source.id == _id
  ][0],
`

export const muxVideoFragment = groq`
  muxVideo {
    asset->,
  },
`

export const videoFragment = groq`
  type,
  settings,
  aspectRatio,
  borderRadius,
  ${muxVideoFragment}
  ${vimeoVideoFragment}
`
