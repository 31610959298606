import { SanityModule } from '@data/sanity/queries/types/modules'
import {
  SanityProductFragment,
  SanityProductVariantFragment,
} from '@data/sanity/queries/types/product'
import { SanityCart } from '@data/sanity/queries/types/site'
import { Reviews } from '@lib/review'

import Module from './module'

interface ModulesProps {
  modules: SanityModule[] | null
  product?: SanityProductFragment
  activeVariant?: SanityProductVariantFragment
  onVariantChange?: (variantId: number) => void
  collectionProducts?: SanityProductFragment[]
  featuredProductIds?: number[]
  cartSettings?: SanityCart
  reviews?: Reviews
}

const Modules = ({
  modules,
  reviews,
  product,
  activeVariant,
  onVariantChange,
  collectionProducts,
  featuredProductIds,
  cartSettings,
}: ModulesProps) => {
  if (!modules) {
    return null
  }

  return (
    <>
      {modules.map((module) => (
        <Module
          key={module._key}
          module={module}
          cartSettings={cartSettings}
          collectionProducts={collectionProducts}
          featuredProductIds={featuredProductIds}
          product={product}
          activeVariant={activeVariant}
          onVariantChange={onVariantChange}
          reviews={reviews}
        />
      ))}
    </>
  )
}

export default Modules
