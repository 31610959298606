import groq from 'groq'

import { imageFragment } from './image'

export const blogAuthorFragment = groq`
  name,
  "slug": slug.current,
  photo {
    ${imageFragment}
  },
`

export const blogCategoryFragment = groq`
  name,
  "slug": slug.current,
`

export const blogPostWithoutBodyFragment = groq`
  "id": _id,
  "date": coalesce(date, _createdAt),
  "modifiedDate": _updatedAt,
  title,
  "slug": slug.current,
  author->{
    ${blogAuthorFragment}
  },
  categories[]->{
    ${blogCategoryFragment}
  },
  excerpt,
  coverImage {
    ${imageFragment}
  },
`

export const blogSettingsQuery = groq`
  *[_type == "blogSettings" && locale == $locale][0]
`
